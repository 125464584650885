import React from 'react'

import { PROPOSAL_COOKIE, makeStorageProvider } from '@azos/core'
import { Loading } from '@azos/shared'
import { Utils } from '@data/utils'
import { makeSimulationLayout } from '@main/factories/components/simulation'
import { makeLoadQuotationByIdService } from '@main/factories/services'
import { GetServerSidePropsContext, NextPageWithLayout } from 'next'
import dynamic from 'next/dynamic'

const SimulationPage: NextPageWithLayout = dynamic(
  () => import('main/factories/pages/simulation-factory'),
  {
    ssr: true,
    loading: () => <Loading />,
  },
)

SimulationPage.getLayout = makeSimulationLayout

export async function getServerSideProps({
  req,
  query,
}: GetServerSidePropsContext) {
  try {
    const quotationExpress = query.widget
      ? Utils.simulation.getQuotationExpress(query.widget.toString())
      : null

    if (!!quotationExpress) {
      return {
        props: {
          widgetQuotationData: quotationExpress,
          initialStep: 5,
        },
      }
    }

    const storageProvider = makeStorageProvider(req.cookies)
    const proposalCookie = storageProvider.getJSON<{
      quotationId?: string
    }>(PROPOSAL_COOKIE)

    if (!proposalCookie?.quotationId) {
      return {
        props: {
          quotationData: null,
        },
      }
    }

    const loadLastQuotationService = makeLoadQuotationByIdService()

    const quotation = await loadLastQuotationService
      .execute(proposalCookie?.quotationId)
      .catch(() => null)

    return {
      props: {
        quotationData: quotation,
      },
    }
  } catch (e) {
    return {
      props: {
        quotationData: null,
      },
    }
  }
}

export default SimulationPage
